// Headings
@font-face {
  font-family: 'PlusJakartaSans';
  font-style: normal;
  src: url('/fonts/PlusJakartaSans.woff2'); /* IE9 Compat Modes */
  src:
    local('PlusJakartaSans'),
    url('/fonts/PlusJakartaSans.woff2') format('truetype'); /* IE6-IE8 */
}

// Body
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  src: url('/fonts/Figtree.woff2'); /* IE9 Compat Modes */
  src:
    local('Figtree'),
    url('/fonts/Figtree.woff2') format('truetype'); /* IE6-IE8 */
}
