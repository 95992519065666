/* 
TODO
1. Shorten this
2. Migrate this to sass
 */

html,
body {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  text-align: left;
  margin: 0;
  padding: 0;
  color: var(--text);
  // font-family: 'Figtree';
  overflow-x: hidden; // todo: implement this
  background-color: var(--background);
  scroll-behavior: smooth;

  // font-size: 16px;
  // root-font-size is 10px but is clamped
  // Read more about clamping & clamping function generator below:
  // https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
  // clamp( minSize, preferred, maxSize)

  // mobile viewport between 320px & 767px  - NO Clamping
  // @media screen and (max-width: 767px) {
  //   //font-size: clamp(8.5px, 7.426px + 0.3356vw, 10px);
  //   font-size: 16px;
  // }

  // // tablet viewport between 768 & 1023 - NO Clamping
  // @media screen and (min-width: 768px) and (max-width: 1023px) {
  //   font-size: clamp(0.7rem, -0.2035rem + 1.8824vw, 1rem);
  // }

  // // clamps desktop viewport between 1024 & 1540
  // @media screen and (min-width: 1024px) {
  //   font-size: clamp(1.5rem, 1.0493rem + 0.7042vw, 2rem);
  // }
}

h1,
h1 *,
h2,
h2 *,
h3,
h3 *,
h4,
h4 *,
h5,
h5 *,
h6,
h6 * {
  font-family: 'PlusJakartaSans', sans-serif;
}

* {
  font-family: 'FigTree';
}

.heading {
  margin: auto;
  z-index: 0;
  color: #242424;
  font-family: 'PlusJakartaSans';
}

.subheading {
  z-index: 0;
  // font-size: 22px;
  color: #616161;
  font-family: 'PlusJakartaSans';
  font-weight: '900';
}

.subheadingHover {
  z-index: 0;
  font-size: 22px;
  color: #00fcc9;
  font-family: 'PlusJakartaSans';
  font-weight: '900';
}

.subheading2 {
  z-index: 0;
  font-size: 20px;
  color: #616161;
  font-family: 'PlusJakartaSans';
  font-weight: '700';
}

.app {
  width: 100vw;
  height: 100vh;
}

.home,
.events,
.guests,
.reports,
.settings {
  display: flex;
  height: 30vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.topBar {
  background-color: #f5f7fb;
  height: 80px;
  display: flex;
  /* align-items: left; */
  width: 100vw;
  justify-content: flex-end;
  /* border-bottom: 1px solid #242424; */
  z-index: 2;
  position: 'absolute';
  overflow: 'hidden';
  box-shadow: 20px 20px;
}

.loginBackground {
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  background-color: #f5f7fb;
  background-image: url(/assets/loginbg.jpg);

  background-color: #242424;
  background-repeat: no-repeat;
  background-size: cover;
}

.hostsLandingBackground {
  width: 100%;
  align-items: center;
  overflow-y: auto;
  background-color: #f5f7fb;
  background-image: url(/assets/comedian.jpg);
  background-position: center;
  background-color: #242424;
  background-repeat: no-repeat;
  background-size: cover;
}

.contactBackground {
  align-items: center;
  overflow-y: auto;
  background-color: #f5f7fb;
  background-image: url(/assets/contactPhone.png);
  background-position: center;
  background-color: #242424;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutBackground {
  width: 100%;
  align-items: center;
  overflow-y: auto;
  background-color: #f5f7fb;
  background-image: url(/assets/handsUp.jpeg);
  background-position: center;
  background-color: #242424;
  background-repeat: no-repeat;
  background-size: cover;
}

.login {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 100vh;
}

.paymentBox {
  justify-content: 'center';
  border-width: 1px;
  border-color: #e5e5e5;
  border-radius: 5px;
  padding: 25px;
}

.gaugeStyle {
  width: 100%;
  height: 100%;
}

.pages-content-menuless {
  padding-top: 50px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.bodyContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
}

.backBar {
  background-color: #f5f7fb;
  padding-left: 280px;
  padding-top: 120px;
  display: 'inline-block';
  width: calc(100vw);
  display: flex;
  align-items: center; /* vertical align */
}

.backBarChevron {
  padding-left: 125px;
  width: 25vw;
}

.backBarTitle {
  display: 'flex';
  padding-left: 125px;
  width: 50vw;
  margin-right: 25vw;
  padding-top: 15px;
  text-align: center;
  flex-grow: 1; /* take up rest of line */
  text-align: center; /* centre text in p */
}

.guestSlider {
  width: 60vw;
  transition: 1s;
  transform: translateX(520px);
}

.guestSliderActive {
  width: 520px;
  transform: translateX(-520vw);
  transition: 1s;
  right: 0;
}

.logotype {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 300px;
}

.logotypeHorizontal {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo-login {
  align-items: center;
  justify-content: center;
  width: 50px;
  text-align: center;
  align-self: center;
}

.sessami-login {
  font-size: 10vw;
}

.input-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 10px;
}

.input-boxes {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.button {
  background-color: #ff0000;
  border: none;
  color: white;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.hollowButton {
  background-color: #ff0000;
  border: none;
  color: white;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.side-hero {
  display: flex;
  background-color: #242424;
  height: 100vh;
  padding: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #ffffff;
  font-size: 5rem;
  float: left;
}

.centered-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  // font-size: (10px + 2vw);
  font-size: 10px;
  padding-top: 200px;
}

.logoutModal {
  width: 500px;
  margin-top: 200px;
  margin-left: 42vw;
  background-color: #f5f7fb;
  padding: 25px;
  border: 1px solid #ff0000;
  border-radius: 10px;
  text-align: center;
}

.menuOverlay {
  width: 100vw;
  height: 100vh;
  align-items: right;
  background-color: #242424;
  opacity: 0.8;
  text-align: center;
  padding-top: 20vh;
}

.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
}

.settingsCardsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-start;
}

.sectionCard {
  padding: 40px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 35vw;
}

.eventCardsWrapper {
  display: flex;
  flex-direction: 'row';
}

.eventCard {
  width: 260px;
  height: 380px;
  margin: 20px;
  padding: 0;
  text-align: left;
}

.eventCardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0px;
  margin: 0px;
}

.eventCardImagePast {
  background-color: #ff0000;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0px;
  margin: 0px;
  opacity: 0.5;
}

.eventPreviewImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.eventPreviewImageHeader {
  @apply h-72 object-cover rounded-t-xl;
}

.cardsWrapperWithPreview {
  display: grid;
  grid-template-columns: repeat(1, 0fr);
  align-items: center;
  justify-content: center;
}

.previewCard {
  // margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 45vw;
  padding: 20px;
}

.preview {
  // margin: 20px;
  width: 428px;
}

.appPreviewHeaderImage {
  margin-left: 0;
  padding-left: 0;
  justify-content: center;
  align-items: center;
  background-color: #ff0000;
}

.previewCard {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 45vw;
  padding: 20px;
}

.footer-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #242424;
  // font-size: calc(8px + 1vw);
  font-size: 8px;
  padding-bottom: 10px;
  padding-top: 200px;
}

.centered-heading {
  margin: auto;
  align-items: center;
  align-self: center;
  text-align: center;
  padding: 10px;
  z-index: 0;
  margin-top: 100px;
}

.form-field {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 10px;
  color: #616161;
}

.form-field-hook-forms {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  margin-right: 10px;
  padding-left: 5px;
  /* margin-left: 10px; */
  color: #616161;
  border-color: #bababa;
  border-width: 1px;
  border-radius: 5px;
}

.form-field-label {
  font-size: 16px;
  color: #616161;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  /* padding-left:10px; */
}

.icon {
  font-size: 2.5rem;
  color: #ff0000;
  display: flex;
}

.browser-warning {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: #f5f7fb;
  z-index: 100;
  text-align: center;
  padding-top: 20vh;
  color: #242424;
  padding-left: 25px;
  padding-right: 25px;
}

p {
  font-size: 20px;
  /* margin:0em;
  padding:0px; */
}

.bodyText {
  // font-size: 22px;
}

.dropzoneUploader {
  background-color: #f5f7fb;
}

.PWAicon {
  color: #f5f7fb;
}

.PWAiconActive {
  color: #00fcc9;
}

/* ONBOARDING */

.onboardingCardsContainer {
  display: flex;
  width: 100vw;
  justify-content: center;
  padding-top: 50px;
}

.onboardingCards {
  width: 40vw;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
}

.address-lookup-wrapper {
  width: 60%;
  padding: 5px;
}

.address-lookup-search-button {
  color: #ffffff;
  background-color: #00fcc9;
  padding: 10px;
  margin-top: 10px;
  border-width: 0px;
  border-radius: 5px;
}
.address-lookup-select {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #d6d9dc;
  height: 40px;
  border-radius: 5px;
  text-align: center;
}

.address-lookup-input {
  width: 100%;
  border: 1px solid #d6d9dc;
  height: 40px;
  border-radius: 5px;
  text-align: center;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #d6d9dc;
  height: 40px;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 1160px) {
  .topBar {
    display: none;
  }
  .previewCard {
    width: 80vw;
  }
  .mobilePreviewHider {
    display: none;
  }
  .cardsWrapperWithPreview {
    grid-template-columns: repeat(1, 0fr);
    justify-content: center;
    align-items: center;
  }
  .sectionCard {
    width: 60vw;
  }
  .settingsCardsWrapper {
    grid-template-columns: repeat(1, 0fr);
    justify-content: center;
  }
  .cardsWrapper {
    grid-template-columns: repeat(1, 0fr);
    justify-content: center;
    align-items: center;
  }
  .logoutModal {
    width: 40vw;
    margin-left: 36vw;
  }
}

.eventCardDateText {
  font-size: 14px;
  margin: 0px;
}

.eventCardDateTextPast {
  font-size: 14px;
  color: #ff0000;
  margin: 0px;
}

.eventCardNameText {
  font-size: 20px;
  margin: 0px;
  text-decoration: none;
  color: '#242424';
}

.eventCardLocationText {
  font-size: 18px;
  margin: 0px;
  text-decoration: none;
  color: '#242424';
}

.eventCardTicketsText {
  font-size: 14px;
  margin: 0px;
  text-decoration: none;
  color: '#242424';
}

.guestSearchField {
  width: 100%;
  padding-bottom: 10px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

// .PWAbar {
//   display: none;
//   background-color: #242424;
//   position: absolute;
//   bottom: -90px;
//   height: 10vh;
//   margin: 0px;
//   width: 100vw;
//   justify-content: center;
// }

@media (max-width: 1800px) {
  .previewCard {
    width: 40vw;
  }
  .cardsWrapperWithPreview {
    justify-content: center;
    grid-template-columns: repeat(1, 0fr);
    align-items: center;
  }
  .sectionCard {
    width: 30vw;
  }
  .settingsCardsWrapper {
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    justify-content: flex-start;
  }
  .cardsWrapper {
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
}

@media (orientation: portrait) {
  .mobilePreviewHider {
    display: none;
  }
  .backBar {
    padding-left: 0;
  }
}

/* MOBILE DISPLAYS */

@media (max-width: 500px) {
  .subheading {
    font-size: 15px;
    color: #000;
  }

  .subheadingHover {
    font-size: 14px;
  }
  .bodyText {
    font-size: 16px;
  }

  .eventCard {
    width: 220px;
    margin: 5px;
    padding: 0;
    text-align: left;
  }

  .paymentBox {
    width: 100vw;
    justify-content: center;
    border-width: 1px;
    border-color: #e5e5e5;
    border-radius: 5px;
    padding: 50px;
    margin-left: -10px;
  }

  .gaugeStyle {
    width: 30vw;
  }

  .addGuestContent {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
  }

  .addGuestLayout {
    padding: 5px;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .form-field-hook-forms {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    padding-left: 2px;
    margin-left: 2px;
    color: #616161;
    border-color: #bababa;
    border-width: 1px;
    border-radius: 5px;
  }

  .topBar {
    background-color: #f5f7fb;
    height: 50px;
    display: flex;
    /* align-items: left; */
    width: 100vw;
    justify-content: flex-end;
    /* border-bottom: 1px solid #242424; */
    z-index: 2;
    position: 'absolute';
    overflow: 'hidden';
    box-shadow: 20px 20px;
  }

  .eventCardDateText {
    font-size: 12px;
  }

  .eventCardNameText {
    margin-top: -2px;
  }

  .eventCardLocationText {
    font-size: 16px;
    margin-top: -2px;
  }

  .eventCardTicketsText {
    font-size: 12px;
    margin-top: -2px;
  }

  .settingsCardsWrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: flex-start;
  }

  .sectionCard {
    // margin-right: 60px;
    // margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80vw;
  }

  .previewCard {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 85vw;
    padding: 10px;
  }

  .backBar {
    background-color: #f5f7fb;
    padding-left: 0px;
    padding-top: 80px;
    display: 'inline-block';
    width: 100vw;
    display: flex;
    align-items: center; /* vertical align */
    justify-content: space-between;
  }

  .subheading {
    z-index: 0;
    font-size: 18px;
    color: #000000;
    font-weight: '900';
  }

  .logoutModal {
    width: 100vw;
    margin-top: 200px;
    margin-left: 0px;
    background-color: #f1f1f1;
    border: 1px solid #bababa;
    border-radius: 20px;
    text-align: center;
  }

  .copyToClipboard {
    display: none;
  }

  .onboardingCards {
    width: 95vw;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px;
  }
}

.h-scroll-container {
  display: flex;
  overflow-x: scroll;
  width: 100vw;
}

.h-scroll-card {
  flex-shrink: 0;
  width: 300px;
  min-height: 180px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.loadGradient {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #d7d7d8 8%, #f1f1f1 38%, #d7d7d8 73%);
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.ticketBgGradient {
  background: linear-gradient(-45deg, #00fcc9, #7c00f6);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -568px 0;
  }
  100% {
    background-position: 568px 0;
  }
}
