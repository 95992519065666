// Tailwind imports
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  // Define Color Variable here
  // It is then used in tailwind.config.js and can be used in SASS as well.
  --primary: #1e3040;
  --primary-light: #8197aa;
  --secondary: #ffffff;
  --secondary-light: #ffffff;
  // --background: #f9f9f9;
  --background: #ffffff;
  --image: #cfd0d2;
  --text: #151c2f;

  --ease-out: cubic-bezier(0, 0, 0.2, 1);
  --ease-in: cubic-bezier(0, 0, 1, 0.2);
  --ease-in-out: cubic-bezier(0.8, 0, 0.2, 1);
}

// Custom Tailwind Classes
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .no-scrollbar::-webkit-scrollbar-thumb:hover {
    background: 'transparent';
  }
  .no-scrollbar::-webkit-scrollbar-track {
    background: 'transparent';
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .fontPrimary {
    font-family: 'Figtree'; // body
  }

  .fontSecondary {
    font-family: 'PlusJakartaSans'; // headings
  }

  .form-section {
    @apply p-2 md:p-4 flex flex-col rounded-lg  bg-white space-y-2 w-full;
    @apply hover:shadow-md hover:bg-slate-100 transition-all hover:cursor-auto;
  }

  .form-input {
    @apply w-full p-2 my-2 border-gray-300 border rounded-lg;
  }

  .form-input-short {
    @apply p-2 my-2 border-gray-300 border rounded-lg;
  }

  .form-input-error {
    @apply w-full p-2 my-2 border-red-500 border rounded-lg text-red-500;
  }
  .form-input-short-error {
    @apply p-2 my-2 border-red-500 border rounded-lg text-red-500;
  }

  .form-submit-btn {
    @apply text-white p-2 bg-primary rounded-lg;
  }

  .dashboardCards {
    @apply p-4 md:p-8 text-center rounded-2xl text-gray-500 drop-shadow-md cursor-pointer flex-1;
    @apply transition-all duration-200 hover:scale-105;
    @apply bg-[#fff] hover:bg-gray-100;
  }

  .graphSideCard {
    @apply py-6 text-center flex flex-col justify-center cursor-pointer drop-shadow-md text-gray-500 rounded-xl;
    @apply transition-all duration-200 hover:scale-105;

    background: white;

    &:hover {
      background: #f1f1f1;
    }
  }

  .pwaBar {
    @apply fixed bottom-0 w-full pt-4 pb-8 px-8 justify-between flex md:hidden bg-primary;
  }

  .card {
    @apply bg-white p-2 md:p-4 shadow-md rounded-xl;
  }

  .profileImage {
    @apply w-32 h-32 avatar rounded-full border-secondary border-4 object-cover;
  }

  .gradientBack {
    @apply bg-gradient-to-b from-primary to-secondary;
  }

  .customPulse {
    animation: pulse 4s cubic-bezier(0.8, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }
  }
}
